// ==================================================================================== //
// SETUP

const sun_socials = [
    ('https://www.youtube.com/@sunscreech', 'youtube'),
    ('https://www.instagram.com/sunscreech/', 'instagram'),
    ('https://soundcloud.com/sunscreech', 'soundcloud'),
    ('https://sunscreech.newgrounds.com', 'newgrounds'),

];

var scroll_amount = document.documentElement.scrollTop;

var socials = [];